import type { ApiNews } from '@app/constants/ApiTypes/entities'
import type { NewsMeta } from '@app/constants/ApiTypes/misc'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'

export const getNewsDescriptor = new ApiActionBuilderDescriptor().setLabel('getNews').setShape<{ meta: NewsMeta; data: ApiNews[] }>()

export const getNewsByIdDescriptor = new ApiActionBuilderDescriptor().setLabel('getNewsById').setShape<{ data: ApiNews }, { id: string }>()

export const postNewsReadDescriptor = new ApiActionBuilderDescriptor().setLabel('postNewsRead').setShape<unknown>()
