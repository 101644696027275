import { PhoneVerificationMethod } from '@app/constants/ApiTypes/misc'
import { PhoneVerificationRequest } from '@app/constants/ApiTypes/requests'

import { api } from '@app/utils/api'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'

import {
  deleteAuthDescriptor,
  getAuthPhoneDeliveryMethodsDescriptor,
  postAuthEmailCodeVerificationDescriptor,
  postAuthEmailRequestDescriptor,
  postAuthEmailTokenVerificationDescriptor,
  postAuthPhoneRequestCodeDescriptor,
  postAuthPhoneVerifyDescriptor,
  postAuthSocialDescriptor,
  postAuthTelegramDescriptor,
} from './auth.descriptors'

export const deleteAuth = new ApiActionBuilder(deleteAuthDescriptor)
  .setInit(() => ({
    method: 'DELETE',
    endpoint: api.path('/api/v2/auth'),
    headers: api.headers(),
  }))
  .build()

export const postAuthSocial = new ApiActionBuilder(postAuthSocialDescriptor)
  .setInit((credentials: { provider: 'facebook' | 'vkontakte'; token: string }) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/social'),
    headers: api.headers(),
    body: JSON.stringify(credentials),
  }))
  .build()

export const getAuthPhoneDeliveryMethods = new ApiActionBuilder(getAuthPhoneDeliveryMethodsDescriptor)
  .setInit((phone: string) => ({
    method: 'GET',
    endpoint: api.path('/api/v2/auth/phone/delivery_methods', { phone }),
    headers: api.headers(),
  }))
  .build()

export const postAuthPhoneRequestCode = new ApiActionBuilder(postAuthPhoneRequestCodeDescriptor)
  .setInit((phone: string, purpose: 'auth' | 'phone_change', delivery: PhoneVerificationMethod) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/phone/request_code'),
    headers: api.headers(),
    body: JSON.stringify({ phone, purpose, delivery }),
    meta: { phone, purpose, delivery },
  }))
  .build()

export const postAuthPhoneVerify = new ApiActionBuilder(postAuthPhoneVerifyDescriptor)
  .setInit((data: PhoneVerificationRequest) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/phone/verify'),
    headers: api.headers(),
    body: JSON.stringify(data),
    meta: { data },
  }))
  .build()

export const postAuthEmailRequest = new ApiActionBuilder(postAuthEmailRequestDescriptor)
  .setInit((email: string, cause?: string) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/email/request'),
    headers: api.headers(),
    body: JSON.stringify({ email, cause }),
    meta: { email, cause },
  }))
  .build()

export const postAuthEmailCodeVerification = new ApiActionBuilder(postAuthEmailCodeVerificationDescriptor)
  .setInit(({ email, code }: { email: string; code: string }) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/email/code_verification'),
    headers: api.headers(),
    body: JSON.stringify({ email, code }),
    meta: { email, code },
  }))
  .build()

export const postAuthEmailTokenVerification = new ApiActionBuilder(postAuthEmailTokenVerificationDescriptor)
  .setInit((token: string) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/auth/email/token_verification'),
    headers: api.headers(),
    body: JSON.stringify({ token }),
    meta: { token },
  }))
  .build()

export const postAuthTelegram = new ApiActionBuilder(postAuthTelegramDescriptor)
  .setInit(
    (
      bot: string,
      auth_data: {
        id: number
        first_name: string
        last_name?: string
        username?: string
        photo_url?: string
        auth_date: number
        hash: string
      }
    ) => ({
      method: 'POST',
      endpoint: api.path('/api/v2/auth/telegram'),
      headers: api.headers(),
      body: JSON.stringify({ bot, auth_data }),
    })
  )
  .build()
