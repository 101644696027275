import { createSelector } from 'reselect'
import { v4 } from 'uuid'

import { ApiNews } from '@app/constants/ApiTypes/entities'

import moment from '@app/utils/moment'
import { assertApiActionResponse } from '@app/utils/performFetchData'

import { ActionRequiredError } from '@app/packages/ActionRequiredError/ActionRequiredError'
import { intoResult, unwrapResult } from '@app/packages/Result/Result'

import { getNews, postNewsRead } from '@app/store/actions/api/news'
import { postNewsReadDescriptor } from '@app/store/actions/api/news.descriptors'
import { unwrapApiActionResult } from '@app/store/apiMiddleware/utils'
import { createReduxSlice } from '@app/store/redux_slice'
import { profileUserResultSelector } from '@app/store/selectors/profile'
import { createThunk } from '@app/store/thunk'

const newsSlice = createReduxSlice<{ hash: string }>('news')
const newsSyncSlice = createReduxSlice<{ hash: string }>('news_sync')

const newsStateSlice = createReduxSlice<{ loadedAt: number; news: ApiNews[]; next: string | null }>('news_state').addCase(
  postNewsReadDescriptor.shapes.fulfilled,
  (state, _action) => {
    if (!state) return state
    let changed = false
    const models = state.news.map(m => {
      if (m.attributes.read) return m
      changed = true
      return { ...m, attributes: { ...m.attributes, read: true } }
    })
    if (!changed) return state
    return { ...state, news: models }
  }
)
const newsLoadingSlice = createReduxSlice<{ loading: boolean }>('news_loading')

export const unsetNewsSync = () => newsSyncSlice.set({ hash: v4() })

export const newsResultSelector = createSelector(
  [newsSlice.selector, newsSyncSlice.selector, newsStateSlice.selector, profileUserResultSelector],
  (meta, sync, state, userResult) =>
    intoResult(() => {
      const user = unwrapResult(userResult)
      if (!user || user.account_type === 'visitor') return []
      const hash = [user.id, sync?.hash || ''].join(':')
      if (!meta || meta.hash !== hash) {
        throw ActionRequiredError.create('News must be fetched', hash, async dispatch => {
          await dispatch(fetchInitialNews())
          dispatch(newsSlice.set({ hash }))
        })
      }
      if (!state) throw new Error('News state is missing')
      return state.news
    })
)
export const newsUnreadCountResultSelector = createSelector([newsResultSelector], articlesResult =>
  intoResult(() => {
    const articles = unwrapResult(articlesResult)
    return articles.filter(a => !a.attributes.read).length
  })
)

export const newsHasMoreSelector = createSelector([newsStateSlice.selector], meta => {
  if (!meta) return false
  return meta.next !== null
})
export const newsLoadingSelector = createSelector([newsLoadingSlice.selector], meta => {
  if (!meta) return false
  return meta.loading
})

export const fetchInitialNews = () =>
  createThunk(async dispatch => {
    using stack = new DisposableStack()
    dispatch(newsLoadingSlice.set({ loading: true }))
    stack.defer(() => {
      dispatch(newsLoadingSlice.set({ loading: false }))
    })
    const resp = await dispatch(getNews({ page: 1, per_page: 10 })).then(assertApiActionResponse(dispatch, 'News fetch failed'))
    dispatch(
      newsStateSlice.set({
        loadedAt: moment().unix(),
        news: resp.data,
        next: resp.data.length < 10 ? null : (resp.data.at(-1)?.attributes.published_at ?? null),
      })
    )
    return resp
  })

export function fetchNextNews() {
  return createThunk(async dispatch => {
    using stack = new DisposableStack()
    const state = dispatch(newsStateSlice.get())
    if (dispatch(newsLoadingSlice.get())?.loading) return
    if (!state) return
    if (state.next === null) return
    dispatch(newsLoadingSlice.set({ loading: true }))
    stack.defer(() => {
      dispatch(newsLoadingSlice.set({ loading: false }))
    })
    const resp = await dispatch(getNews({ before: moment(state.next).unix(), per_page: 10 })).then(assertApiActionResponse(dispatch, 'News fetch failed'))
    dispatch(
      newsStateSlice.set({
        ...state,
        news: [...state.news, ...resp.data],
        next: resp.data.length < 10 ? null : (resp.data.at(-1)?.attributes.published_at ?? null),
      })
    )
    return resp
  })
}

export function newsMarkAsRead() {
  return createThunk(async (dispatch, getState) => {
    const state = getState()
    // emulate "mark as read" in supervise mode
    if (state.session.supervisor || state.session.supervisor) {
      dispatch(postNewsRead.fulfill(undefined))
      return
    }

    return await unwrapApiActionResult(dispatch(postNewsRead()))
  })
}
