import { Middleware } from 'redux'

import { AnalyticsEvent } from '@app/services/AnalyticsEvent'

import { matchReduxAction } from '@app/utils/matchReduxAction'

import {
  cancelAnnouncementResponseDescriptor,
  createAnnouncementResponseDescriptor,
  declineAnnouncementResponseDescriptor,
  updateAnnouncementResponseDescriptor,
} from '@app/store/actions/announcementResponse.descriptors'
import {
  postAuthEmailCodeVerificationDescriptor,
  postAuthPhoneRequestCodeDescriptor,
  postAuthPhoneVerifyDescriptor,
  postAuthSocialDescriptor,
} from '@app/store/actions/api/auth.descriptors'
import { postRequestsDescriptor } from '@app/store/actions/api/requests.descriptors'
import {
  getDepersonalizedAnnouncementsSearchDescriptor,
  getDepersonalizedAnnouncementsSearchMapDescriptor,
} from '@app/store/actions/depersonalized_announcements.descriptors'
import { asNetworkError } from '@app/store/apiMiddleware/errors'

const middleware: Middleware = _store => next => action => {
  // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
  switch (true) {
    case matchReduxAction(action, createAnnouncementResponseDescriptor.shapes.fulfilled): {
      AnalyticsEvent.create({ id: 'create_announcement_response', announcement_id: action.meta.request.announcement_id }).sendGAYS()
      break
    }
    case matchReduxAction(action, createAnnouncementResponseDescriptor.shapes.rejected): {
      new AnalyticsEvent('create_announcement_response_fail', { announcement_id: action.meta.request.announcement_id }).sendAmplitude()
      break
    }
    case matchReduxAction(action, cancelAnnouncementResponseDescriptor.shapes.fulfilled): {
      AnalyticsEvent.create({
        id: 'cancel_announcement_response',
        announcement_id: action.meta.announcement_id,
        announcement_response_id: action.meta.response_id,
      }).sendGAYS()
      break
    }
    case matchReduxAction(action, cancelAnnouncementResponseDescriptor.shapes.rejected): {
      new AnalyticsEvent('cancel_announcement_response_fail', {
        announcement_id: action.meta.announcement_id,
        announcement_response_id: action.meta.response_id,
      }).sendAmplitude()
      break
    }
    case matchReduxAction(action, updateAnnouncementResponseDescriptor.shapes.fulfilled): {
      AnalyticsEvent.create({
        id: 'update_announcement_response',
        announcement_response_id: action.meta.response_id,
        announcement_id: action.meta.announcement_id,
      }).sendGAYS()
      break
    }
    case matchReduxAction(action, updateAnnouncementResponseDescriptor.shapes.rejected): {
      new AnalyticsEvent('create_announcement_response_fail', {
        response_id: action.meta.response_id,
        announcement_id: action.meta.announcement_id,
      }).sendAmplitude()
      break
    }

    case matchReduxAction(action, declineAnnouncementResponseDescriptor.shapes.fulfilled): {
      AnalyticsEvent.create({
        id: 'decline_announcement_response',
        announcement_id: action.meta.announcement_id,
        announcement_response_id: action.meta.response_id,
      }).sendGAYS()
      break
    }
    case matchReduxAction(action, declineAnnouncementResponseDescriptor.shapes.rejected): {
      new AnalyticsEvent('decline_announcement_response_fail', {
        announcement_id: action.meta.announcement_id,
        announcement_response_id: action.meta.response_id,
      }).sendAmplitude()
      break
    }

    case matchReduxAction(action, postRequestsDescriptor.shapes.fulfilled): {
      AnalyticsEvent.create({ id: 'create_request_from_announcement', announcement_response_id: action.meta.announcement_response_id }).sendGAYS()
      break
    }
    case matchReduxAction(action, postRequestsDescriptor.shapes.rejected): {
      new AnalyticsEvent('create_request_from_announcement_fail', { announcement_response_id: action.meta.announcement_response_id }).sendAmplitude()
      break
    }

    case matchReduxAction(action, getDepersonalizedAnnouncementsSearchDescriptor.shapes.pending): {
      new AnalyticsEvent('fetch_depersonalized_announcements.start', {}).sendAmplitude()
      break
    }
    case matchReduxAction(action, getDepersonalizedAnnouncementsSearchDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('fetch_depersonalized_announcements.fetch', {
        request: action.meta.request,
      }).sendAmplitude()
      break
    }
    case matchReduxAction(action, getDepersonalizedAnnouncementsSearchDescriptor.shapes.rejected): {
      new AnalyticsEvent('fetch_depersonalized_announcements.error', {
        error: action.payload.message,
        status: asNetworkError(action.payload)?.status || 'unknown',
      }).sendAmplitude()
      break
    }

    case matchReduxAction(action, getDepersonalizedAnnouncementsSearchMapDescriptor.shapes.pending): {
      new AnalyticsEvent('fetch_depersonalized_announcements_map.start', {}).sendAmplitude()
      break
    }
    case matchReduxAction(action, getDepersonalizedAnnouncementsSearchMapDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('fetch_depersonalized_announcements_map.fetch', {
        request: action.meta.request,
      }).sendAmplitude()
      break
    }
    case matchReduxAction(action, getDepersonalizedAnnouncementsSearchMapDescriptor.shapes.rejected): {
      new AnalyticsEvent('fetch_depersonalized_announcements_map.error', {
        error: action.payload.message,
        status: asNetworkError(action.payload)?.status || 'unknown',
      }).sendAmplitude()
      break
    }

    case matchReduxAction(action, postAuthSocialDescriptor.shapes.pending): {
      new AnalyticsEvent('social_login', {}).sendAmplitude()
      break
    }
    case matchReduxAction(action, postAuthSocialDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('social_login_success', {}).sendAmplitude()
      break
    }
    case matchReduxAction(action, postAuthSocialDescriptor.shapes.rejected): {
      new AnalyticsEvent('social_login_fail', {}).sendAmplitude()
      break
    }

    case matchReduxAction(action, postAuthPhoneRequestCodeDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('phone_verification', { purpose: action.meta.purpose, delivery: action.meta.delivery }).sendAmplitude()
      break
    }
    case matchReduxAction(action, postAuthPhoneRequestCodeDescriptor.shapes.rejected): {
      new AnalyticsEvent('phone_verification_fail', { purpose: action.meta.purpose, delivery: action.meta.delivery }).sendAmplitude()
      break
    }

    case matchReduxAction(action, postAuthPhoneVerifyDescriptor.shapes.pending): {
      new AnalyticsEvent('phone_confirmation', {}).sendAmplitude()
      break
    }
    case matchReduxAction(action, postAuthPhoneVerifyDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('phone_confirmation_success', {}).sendAmplitude()
      break
    }
    case matchReduxAction(action, postAuthPhoneVerifyDescriptor.shapes.rejected): {
      new AnalyticsEvent('phone_confirmation_fail', {}).sendAmplitude()
      break
    }

    case matchReduxAction(action, postAuthEmailCodeVerificationDescriptor.shapes.pending): {
      new AnalyticsEvent('email_code_verification', {}).sendAmplitude()
      break
    }
    case matchReduxAction(action, postAuthEmailCodeVerificationDescriptor.shapes.fulfilled): {
      new AnalyticsEvent('email_code_verification_success', {}).sendAmplitude()
      break
    }
    case matchReduxAction(action, postAuthEmailCodeVerificationDescriptor.shapes.rejected): {
      new AnalyticsEvent('email_code_verification_fail', {}).sendAmplitude()
      break
    }
  }

  return next(action)
}

export default middleware
