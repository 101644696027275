import { createSelector } from 'reselect'
import { v4 } from 'uuid'

import { isAbortError } from '@app/errors/AbortError'

import { withAbortSignal } from '@app/packages/abortContext/actions'
import { asError } from '@app/packages/asError/asError'

import {
  postAuthEmailCodeVerificationDescriptor,
  postAuthEmailTokenVerificationDescriptor,
  postAuthPhoneVerifyDescriptor,
  postAuthSocialDescriptor,
  postAuthTelegramDescriptor,
} from '@app/store/actions/api/auth.descriptors'
import { deleteAvatars, postAvatars } from '@app/store/actions/api/avatars'
import { getUsersMeDescriptor } from '@app/store/actions/api/users.descriptors'
import { postTOSAcceptanceDescriptor } from '@app/store/actions/profile.descriptors'
import { unwrapApiActionResult } from '@app/store/apiMiddleware/utils'
import { createReduxSlice } from '@app/store/redux_slice'
import { avatarsSelector } from '@app/store/selectors/misc'
import { createThunk } from '@app/store/thunk'

const avatarsSlice = createReduxSlice<{ list: string[] }>('profile_avatars')
  .addCases(
    [
      getUsersMeDescriptor.shapes.fulfilled,
      postAuthSocialDescriptor.shapes.fulfilled,
      postAuthTelegramDescriptor.shapes.fulfilled,
      postAuthEmailCodeVerificationDescriptor.shapes.fulfilled,
      postAuthEmailTokenVerificationDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      if (!action.payload.included) return state
      const avatars = action.payload.included.filter(i => i.type === 'avatars')
      return { list: avatars.map(a => a.id) }
    }
  )
  .addCases([postTOSAcceptanceDescriptor.shapes.fulfilled, postAuthPhoneVerifyDescriptor.shapes.fulfilled], (state, action) => {
    if (!state) return state
    if (!action.payload.data?.relationships?.avatars?.data) return state
    if (action.payload.data.relationships.avatars.data.length === 0) return { list: [] }
    return { list: action.payload.data.relationships.avatars.data.map(item => item.id) }
  })

const avatarUploadSlice = createReduxSlice<{ list: AvatarUploadItem[] }>('profile_avatar_upload')

export type AvatarUploadItem = { type: 'upload'; id: string; preview: string; percent: number; error?: Error; abort: () => void }

const profileAvatarIdsSelector = createSelector([avatarsSlice.selector], state => state?.list || [])
const profileAvatarsUploadSelector = createSelector([avatarUploadSlice.selector], state => state?.list || [])

export const profileAvatarsSelector = createSelector([profileAvatarIdsSelector, avatarsSelector], (ids, avatars) => {
  return ids.map(id => {
    const av = avatars[id]
    if (!av) throw new Error(`Avatar with id ${id} not found`)
    return av
  })
})

export const profileAvatarSelector = createSelector([profileAvatarsSelector], avatars => avatars.at(0))

export const profileAvatarsEditorSelector = createSelector([profileAvatarsSelector, profileAvatarsUploadSelector], (avatars, uploads) => {
  return [...avatars, ...uploads]
})

export const profileHasAvatarsSelector = createSelector([profileAvatarsSelector], avatars => !!avatars.length)

export const uploadAvatar = (file: File) => {
  return createThunk(async dispatch => {
    using stack = new DisposableStack()
    const abortController = new AbortController()
    const descriptor: AvatarUploadItem = { type: 'upload', id: v4(), preview: URL.createObjectURL(file), percent: 0, abort: () => abortController.abort() }

    dispatch(avatarUploadSlice.update(state => (state ? { ...state, list: [...state.list, descriptor] } : { list: [descriptor] })))

    try {
      let invertedProgress = 1

      const interval = setInterval(() => {
        invertedProgress = invertedProgress - invertedProgress * 0.3
        const percent = (1 - invertedProgress) * 100
        dispatch(avatarUploadSlice.update(state => (state ? { ...state, list: state.list.map(d => (d.id === descriptor.id ? { ...d, percent } : d)) } : state)))
      }, 500)
      stack.defer(() => {
        clearInterval(interval)
      })

      const formData = new FormData()
      formData.append('file', file)

      const payload = await unwrapApiActionResult(dispatch(withAbortSignal(abortController.signal, postAvatars(formData))))
      dispatch(avatarUploadSlice.update(state => (state ? { ...state, list: state.list.filter(d => d.id !== descriptor.id) } : state)))
      const id = payload.data.id
      dispatch(avatarsSlice.update(state => (state ? { ...state, list: [...state.list, id] } : { list: [id] })))
    } catch (e) {
      if (isAbortError(e)) {
        dispatch(avatarUploadSlice.update(state => (state ? { ...state, list: state.list.filter(d => d.id !== descriptor.id) } : state)))
      } else {
        dispatch(
          avatarUploadSlice.update(state =>
            state ? { ...state, list: state.list.map(d => (d.id === descriptor.id ? { ...d, percent: 0, error: asError(e) } : d)) } : state
          )
        )
      }
    }
  })
}

export const deleteAvatar = (id: string) =>
  createThunk(async dispatch => {
    {
      const state = dispatch(avatarUploadSlice.get())
      if (state) {
        const files = state.list
        const file = files.find(i => i.id === id)
        if (file) {
          file.abort()
          dispatch(avatarUploadSlice.update(state => (state ? { ...state, list: state.list.filter(i => i.id !== id) } : state)))
          return
        }
      }
    }
    {
      const state = dispatch(avatarsSlice.get())
      if (state) {
        const present = !!state.list.find(i => i === id)
        if (present) {
          await unwrapApiActionResult(dispatch(deleteAvatars(id)))
          dispatch(avatarsSlice.update(state => (state ? { ...state, list: state.list.filter(i => i !== id) } : state)))
        }
      }
    }
  })
