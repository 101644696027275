import isNil from 'lodash/isNil'
import omit from 'lodash/omit'

import { ApiPlace } from '@app/constants/ApiTypes/entities'
import { ApiDataResponse } from '@app/constants/ApiTypes/misc'
import { ApiDeleteFavoritesUserQuery, ApiPostFavoritesUserRequest, PhoneVerificationRequest, ProfileUpdateRequest } from '@app/constants/ApiTypes/requests'

import { api } from '@app/utils/api'
import { placeCookie, regionCookie } from '@app/utils/routing/region'

import { withAbortSignal } from '@app/packages/abortContext/actions'
import { Task } from '@app/packages/task/Task'

import { ApiActionBuilder } from '@app/store/apiMiddleware/builder'
import { asNetworkError } from '@app/store/apiMiddleware/errors'
import { profileUserSelector } from '@app/store/selectors/profile'
import { defaultRegionModelSelector, regionsModelsSelector } from '@app/store/selectors/regions'
import { createThunk } from '@app/store/thunk'
import { ProfileUpdateState } from '@app/store/types/profile'

import { postAuthEmailCodeVerification, postAuthPhoneVerify } from './api/auth'
import { putUsersById } from './api/users'
import { withProgressAction } from './initial'
import {
  addToFavoritesDescriptor,
  deleteFromFavoritesDescriptor,
  getFavoritesDescriptor,
  postTOSAcceptanceDescriptor,
  setProfilePlaceActionDescriptor,
} from './profile.descriptors'
import { getPlaceById, getPlaceByRegion } from './region'
import { setSession } from './session'
import { createTask } from './tasks'

export function updateProfile(data: ProfileUpdateState) {
  return createThunk(async (dispatch, getState) => {
    const id = getState().profile.user?.id
    if (!id) throw new Error('Profile is empty')
    const patch = convertProfileUpdateStateToProfilePatch(data)
    const response = await dispatch(putUsersById(id, patch))
    if (response && !response.error) {
      await dispatch(setSession({ access_token: getState().session.access_token || '' }))
    }
    return response
  })
}

export const loginWithPhoneVerificationRequest = (data: PhoneVerificationRequest) =>
  createThunk(async (dispatch, _getState) => {
    const resp = await dispatch(postAuthPhoneVerify(data))
    if (resp && !resp.error) {
      await dispatch(setSession({ access_token: resp.payload.data.meta.access_token }))
    }
    return resp
  })

export const loginWithEmailCodeVerification = ({ email, code }: { email: string; code: string }) =>
  createThunk(async (dispatch, _getState) => {
    const response = await dispatch(postAuthEmailCodeVerification({ email, code }))
    if (response && !response.error) {
      await dispatch(setSession({ access_token: response.payload.data.meta.access_token }))
    }
    return response
  })

export const postTOSAcceptance = new ApiActionBuilder(postTOSAcceptanceDescriptor)
  .setInit(() => ({
    method: 'POST',
    endpoint: api.path('/api/v2/tos/acceptance'),
    headers: api.headers(),
  }))
  .build()

export const getFavorites = new ApiActionBuilder(getFavoritesDescriptor)
  .setInit((page: number, per_page: number = 10) => {
    const query = { page, per_page }
    return {
      method: 'GET',
      endpoint: api.path('/api/v2/favorites', query),
      headers: api.headers(),
      bailout: ({ favorites }) => (favorites.meta.loading ? true : page === 1 ? favorites.meta.fetched : favorites.meta.page >= favorites.meta.total_pages),
      meta: { query },
    }
  })
  .build()

export function fetchFavorites(per_page: number) {
  return getFavorites(1, per_page)
}

export function fetchNextFavorites() {
  return createThunk((dispatch, getState) => {
    const { meta } = getState().favorites
    return dispatch(getFavorites((meta.page || 0) + 1, meta.per_page || 10))
  })
}

export const addToFavorites = new ApiActionBuilder(addToFavoritesDescriptor)
  .setInit((request: ApiPostFavoritesUserRequest) => ({
    method: 'POST',
    endpoint: api.path('/api/v2/favorites'),
    headers: api.headers(),
    body: JSON.stringify(request),
    meta: request,
  }))
  .build()

export const deleteFromFavorites = new ApiActionBuilder(deleteFromFavoritesDescriptor)
  .setInit((query: ApiDeleteFavoritesUserQuery) => ({
    method: 'DELETE',
    endpoint: api.path('/api/v2/favorites', query),
    headers: api.headers(),
    meta: query,
  }))
  .build()

export const setProfilePlaceAction = new ApiActionBuilder(setProfilePlaceActionDescriptor)
  .setInit((meta: { cause: 'change' | 'restore' }) => ({
    method: 'GET',
    endpoint: '',
    headers: api.headers(),
    meta,
  }))
  .build()

export function setPlace(payload: ApiDataResponse<ApiPlace>) {
  return createThunk((dispatch, _getState, { abort, cookies }) => {
    abort.clearAbortController(PLACE_ABORT_CONTROLLER_LABEL)
    placeCookie.set(cookies, payload.data.id)

    dispatch<typeof setProfilePlaceAction.descriptor.shapes.fulfilled>({
      type: setProfilePlaceAction.descriptor.shapes.fulfilled.type,
      payload,
      meta: { cause: 'change' },
    })
  })
}

export function changePlace(payload: ApiDataResponse<ApiPlace>) {
  return createThunk(async (dispatch, _getState, { router }) => {
    dispatch(setPlace(payload))
    router?.history.replace(undefined as any, { scroll: 'save' })
  })
}

export function setPlaceByRegion(regionId: string) {
  return createThunk(async (dispatch, _getState, { abort }) => {
    const abortController = abort.createAbortController('place-by-region-abort-controller')
    const placeResponse = await dispatch(getPlaceByRegion(regionId))
    if (abortController.signal.aborted) return

    dispatch(changePlace(placeResponse))
  })
}

export function restorePlace() {
  return createThunk(async (dispatch, getState, { abort, cookies }) => {
    const state = getState()
    const loading = state.profile.place.loading
    if (loading) return

    const extracted = dispatch(getPlaceId())
    const existing = state.profile.place.place_id
    if (existing && existing === extracted) return
    const abortController = abort.createAbortController(PLACE_ABORT_CONTROLLER_LABEL)

    dispatch<typeof setProfilePlaceAction.descriptor.shapes.pending>({
      type: setProfilePlaceAction.descriptor.shapes.pending.type,
      meta: { cause: 'restore' },
    })

    let placeResponse = (await dispatch(getPlaceById(extracted)))!

    if (abortController.signal.aborted) return

    if (placeResponse.error && asNetworkError(placeResponse.payload)?.status === 404) {
      placeCookie.rm(cookies)
      regionCookie.rm(cookies)
      placeResponse = (await dispatch(getPlaceById(dispatch(getDefaultPlaceId()))))!
    }

    if (abortController.signal.aborted) return

    if (placeResponse.error) {
      dispatch<typeof setProfilePlaceAction.descriptor.shapes.rejected>({
        type: setProfilePlaceAction.descriptor.shapes.rejected.type,
        error: true,
        payload: placeResponse.payload,
        meta: { cause: 'restore' },
      })

      throw placeResponse.payload
    }

    dispatch<typeof setProfilePlaceAction.descriptor.shapes.fulfilled>({
      type: setProfilePlaceAction.descriptor.shapes.fulfilled.type,
      payload: placeResponse.payload,
      meta: { cause: 'restore' },
    })

    return
  })
}

const PLACE_ABORT_CONTROLLER_LABEL = 'profile-place'

function getDefaultPlaceId() {
  return createThunk<string>((_dispatch, getState) => {
    return defaultRegionModelSelector(getState()).relationships.default_place.data!.id
  })
}

function getPlaceId() {
  return createThunk<string>((_dispatch, getState, { cookies }) => {
    const state = getState()
    const user = profileUserSelector(state)
    if (user && 'place_id' in user) {
      const id = isNil(user.place_id) ? null : String(user.place_id)
      if (id) return id
    }

    if (user && 'region_id' in user) {
      const regionId = !isNil(user.region_id) ? String(user.region_id) : null
      const region = (regionId && Object.values(regionsModelsSelector(state)).find(r => r.id === regionId)) || defaultRegionModelSelector(state)
      return region.relationships.default_place.data!.id
    }

    const placeId = placeCookie.get(cookies)
    if (placeId) return placeId

    const regionSlug = regionCookie.get(cookies)
    const region = Object.values(regionsModelsSelector(state)).find(r => r.attributes.slug === regionSlug) || defaultRegionModelSelector(state)
    return region.relationships.default_place.data!.id
  })
}

function convertProfileUpdateStateToProfilePatch(state: ProfileUpdateState): ProfileUpdateRequest {
  const patch = Object.fromEntries(
    Object.entries(state).flatMap(([key, value]): [string, any][] => {
      // eslint-disable-next-line @typescript-eslint/switch-exhaustiveness-check
      switch (key as keyof ProfileUpdateState) {
        case 'location': {
          if (!state.location) return []
          return [
            ['address', state.location.address],
            ['latitude', state.location.latitude],
            ['longitude', state.location.longitude],
          ]
        }
        case 'place': {
          const place = state.place
          if (!place) return [['place_id', undefined]]
          return [['place_id', place.place.id]]
        }
        default:
          return [[key, value]]
      }
    })
  ) as ProfileUpdateRequest
  return patch
}

export const getProfileUpdateTask = () =>
  createTask('Profile update task', dispatch =>
    Task.create(ctx => async (update: ProfileUpdateState) => {
      return await dispatch(
        withAbortSignal(
          ctx.abortController.signal,
          withProgressAction(updateProfile(omit({ ...update, birthday: update.birthday || undefined }, 'account_type')))
        )
      )
    })
  )
