import type {
  ApiCountry,
  ApiLocality,
  ApiParent,
  ApiParentProfile,
  ApiPlace,
  ApiRegion,
  ApiSitter,
  ApiSitterProfile,
  ApiUserProfile,
} from '@app/constants/ApiTypes/entities'
import type { ApiDataResponse } from '@app/constants/ApiTypes/misc'
import type { ApiDeleteFavoritesUserQuery, ApiPostFavoritesUserRequest } from '@app/constants/ApiTypes/requests'

import { ApiActionBuilderDescriptor } from '@app/store/apiMiddleware/builderDescriptor'
import { createAction } from '@app/store/toolkit'
import type { ProfileUpdateState } from '@app/store/types/profile'

export const editProfile = createAction<'EDIT_PROFILE', ProfileUpdateState>('EDIT_PROFILE')

export const phoneChangeConfirmDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('PHONE_CHANGE_CONFIRM')
  .setShape<ApiDataResponse<ApiUserProfile>, { data: { phone: string; code: string } }>()

export const postTOSAcceptanceDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('POST_TOS_ACCEPTANCE')
  .setShape<ApiDataResponse<ApiParentProfile | ApiSitterProfile>>()

export const getFavoritesDescriptor = new ApiActionBuilderDescriptor().setLabel('GET_FAVORITES').setShape<
  ApiDataResponse<(ApiSitter | ApiParent)[]> & {
    meta: {
      total: number
      total_pages: number
      current_page: number
    }
  },
  {
    query: {
      per_page: number
      page: number
    }
  }
>()

export const addToFavoritesDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('ADD_TO_FAVORITES')
  .setShape<ApiDataResponse<ApiSitter | ApiParent>, ApiPostFavoritesUserRequest>()

export const deleteFromFavoritesDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('DELETE_FROM_FAVORITES')
  .setShape<ApiDataResponse<ApiSitter | ApiParent>, ApiDeleteFavoritesUserQuery>()

export const setProfilePlaceActionDescriptor = new ApiActionBuilderDescriptor()
  .setLabel('SET_PROFILE_PLACE')
  .setShape<{ data: ApiPlace; included: (ApiRegion | ApiLocality | ApiCountry)[] }, { cause: 'change' | 'restore' }>()
