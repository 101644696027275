import { compact } from '@app/utils/compactV2Entity'
import { ensureApiType } from '@app/utils/ensureApiType'
import { mergeGuarded } from '@app/utils/mergeGuarded'

import {
  postAuthEmailCodeVerificationDescriptor,
  postAuthEmailTokenVerificationDescriptor,
  postAuthPhoneVerifyDescriptor,
  postAuthSocialDescriptor,
  postAuthTelegramDescriptor,
} from '@app/store/actions/api/auth.descriptors'
import { postTrainingRequestDescriptor } from '@app/store/actions/api/training_request.descriptors'
import { getUsersMeDescriptor, putUsersByIdDescriptor, putUsersByIdPhoneDescriptor } from '@app/store/actions/api/users.descriptors'
import { phoneChangeConfirmDescriptor, postTOSAcceptanceDescriptor } from '@app/store/actions/profile.descriptors'
import { createReducer } from '@app/store/toolkit'
import { StoreParentProfile, StoreProfileUser, StoreSitterProfile, StoreVisitorProfile } from '@app/store/types/profile'

export default createReducer<StoreProfileUser>(null, builder => {
  builder.addCases(
    [
      getUsersMeDescriptor.shapes.fulfilled,
      postAuthSocialDescriptor.shapes.fulfilled,
      postAuthTelegramDescriptor.shapes.fulfilled,
      postTOSAcceptanceDescriptor.shapes.fulfilled,
      postAuthPhoneVerifyDescriptor.shapes.fulfilled,
      phoneChangeConfirmDescriptor.shapes.fulfilled,
      putUsersByIdDescriptor.shapes.fulfilled,
      postAuthEmailCodeVerificationDescriptor.shapes.fulfilled,
      postAuthEmailTokenVerificationDescriptor.shapes.fulfilled,
      putUsersByIdPhoneDescriptor.shapes.fulfilled,
    ],
    (state, action) => {
      const user = action.payload.data
      if (!user) throw new Error(`Got not a user on action: ${action.type}`)

      const included = action.payload.included || []

      switch (user.attributes.account_type) {
        case 'parent': {
          const o: StoreParentProfile = {
            id: user.id,
            ...user.attributes,
            subscription: (() => {
              const s = included.find(ensureApiType('subscriptions'))
              return s ? compact(s) : null
            })(),
          }
          return state?.account_type === 'parent' ? mergeGuarded(state, o, 'subscription', 'phone') : o
        }
        case 'sitter': {
          const o: StoreSitterProfile = {
            id: user.id,
            ...user.attributes,
            location: (() => {
              const s = included.find(ensureApiType('locations'))
              return s ? compact(s) : null
            })(),
          }
          return state?.account_type === 'sitter' ? mergeGuarded(state, o, 'location', 'phone') : o
        }
        case 'visitor': {
          const o: StoreVisitorProfile = {
            id: user.id,
            ...user.attributes,
          }
          return state?.account_type === 'visitor' ? mergeGuarded(state, o) : o
        }
        default: {
          return state
        }
      }
    }
  )

  builder.addCase(postTrainingRequestDescriptor.shapes.fulfilled, (state, _action) => {
    if (state?.account_type === 'sitter') {
      return { ...state, has_training_request: true }
    }
    return state
  })
})
